import React from 'react';
//import Layout from '../components/Layout';
import { theme } from '../styles/theme';
import { ThemeProvider } from '../styles/ThemeProvider';
import styled from 'styled-components/macro';

import Grid from '../components/Layout/Grid.jsx';
import FullWidthSection from '../components/Layout/FullWidthSection';
import MaxWidthSection from '../components/Layout/MaxWidthSection';
import Row from '../components/Layout/Row.jsx';

const Header = styled(FullWidthSection)`
  padding: 4rem 1.5rem;
  background-color: lightcoral;
`;

const Nav = styled(Row)`
  background-color: white;
  a {
    margin-right: 1rem;
    color: lightcoral;
  }
`;

const Content = styled(MaxWidthSection)`
  padding: 10rem 5rem;
  background-color: lightblue;
`;

const GridLayout = () => {
  return (
    <ThemeProvider theme={theme}>
      <Grid>
        <Header as="header">
          <Nav as="nav" sd={2} sm={2} em={6} ss={2} se={6}>
            <a href="/">Home</a>
            <a href="/about">About</a>
          </Nav>
        </Header>
        <Content as="main">
          <Row>
            <p>This is the content</p>
          </Row>
        </Content>
      </Grid>
    </ThemeProvider>
  );
};

export default GridLayout;
